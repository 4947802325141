.sku-comment {
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;

  &__title {
    height: 70px;
    padding: 0 20px;
    background-color: $gray-f0efed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    color: $gray-333;
    box-shadow: none;

    span {
      display: flex;
      align-items: baseline;
      h2 {
        font-weight: bold;
        margin-right: 10px;
        margin-bottom: 0;
      }
    }

    &:focus {
      border: none;
      box-shadow: none;
    }

    &::after {
      content: none;
    }

    img {
      width: 48px;
      height: 48px;
    }
  }

  &__button {
    width: 100%;
    height: 40px;
    background-color: $gray-222;
    color: $white;
    border: none;
    margin-bottom: 20px;
  }

  &__item {
    height: 160px;
    background-color: $gray-f0efed;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-between;
    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      span {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        font-weight: bold;
      }
    }
    &__content {
      flex-grow: 1;
      margin-bottom: 0;
      line-height: 14px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
      overflow: hidden;
    }

    &__footer {
      text-align: end;
    }
  }

  &__slider {
    .slick-slide > div {
      margin: 0 6px;
    }
    .slick-list {
      margin: 0 -6px;
    }
  }
}
