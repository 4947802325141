.sku-comment {
  display: flex;
  flex-direction: column;
  &__title {
    height: 48px;
    padding: 0 10.6px;
    background-color: $gray-f0efed;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span {
      display: flex;
      align-items: baseline;
      h2 {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
  }

  &__button {
    width: 120px;
    height: 40px;
    background-color: $gray-222;
    color: $white;
    border: none;
    margin-bottom: 20px;
    font-weight: bold;
  }

  &__item {
    height: 160px;
    background-color: $gray-f0efed;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-between;
    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      span {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        font-weight: bold;
      }
    }
    &__content {
      flex-grow: 1;
      margin-bottom: 0;
      line-height: 14px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
      overflow: hidden;
    }

    &__footer {
      text-align: end;
    }
  }

  &__slider {
    padding: 0 58px;

    .sku-slider__action--prev {
      left: 0;
    }
    .sku-slider__action--next {
      right: 0;
    }
  }
}
