.banner-b8 {
  color: $gray-333;
  width: 100%;

  &__title {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 40px;
    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $gray-222;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    h2 {
      margin: 0;
      padding: 0 20px;
      background-color: $white;
      font-weight: bold;
      z-index: 1;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__item {
      width: 32%;
      margin-bottom: 40px;

      p {
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }

      img {
        width: 100%;
        aspect-ratio: 440 / 320;
      }
    }
  }
}
