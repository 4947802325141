.home {
  .aroundImage {
    width: 100%;
    margin-top: -25%;
    position: relative;

    &__container {
      width: 49.067%;
      margin: 0 auto;
    }

    &__image {
      overflow: hidden;
      border-radius: 50%;
      border: 8px solid white !important;
    }
  }

  .videoCard {
    &__coverText {
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      text-align: center;
      max-width: 320px;
      width: 100%;
    }
  }

  .home-swiper {
    position: relative;
    width: 100%;
  }

  .swipe-card-title {
    flex-direction: column-reverse;

    > div:last-child {
      margin-left: 0 !important;
      font-size: 16px !important;
    }
  }
}
