.sku-tabs {
  margin-bottom: 40px;
  .nav-tabs {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    border-bottom: 3px solid $gray-222;
    .nav-item {
      margin-right: 8px;
      flex-grow: 1;
      max-width: 200px;

      &:last-of-type {
        margin-right: 0;
      }
      .nav-link {
        height: 49px;
        border: none;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $gray-222;
        background-color: $gray-f0efed;
        font-weight: bold;
        margin-bottom: 0;

        &.active {
          background-color: $gray-222;
          color: $white;
        }
      }
    }
  }

  &__content {
    p {
      white-space: pre-wrap;
    }
  }
}
