.banner-b9 {
  color: $gray-333;
  width: 100%;

  &__title {
    width: 100%;
    font-weight: bold;
    text-align: center;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__item {
      width: 100%;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        width: 100%;
        text-align: center;
        margin-top: 10px;
      }

      img {
        width: 100%;
        aspect-ratio: 1 / 1;
      }
    }
  }
}
