.footer {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__link {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 274px;
    background-color: $gray-323232;
    padding: 18px 0 45px 0;

    &__container {
      width: 1008px;

      h2 {
        color: $white;
        margin: 20px 0 10px 0;
      }

      ul {
        margin-bottom: 0;
        li {
          margin-bottom: 0.2rem;
          a {
            color: $gray-ccc;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    &__dpmark {
      width: 85px;
      cursor: pointer;
    }
  }

  &__copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: $dark;
    color: $white;
  }
}
