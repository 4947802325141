.productFilter {
  width: 100%;
  color: $gray-333;
  position: relative;

  &__action {
    display: flex;
    justify-content: space-between;
    position: sticky;
    padding: 10px 0;
    z-index: 3;
    top: 0;
    background-color: var(--bs-white);

    h2 {
      margin-right: 10px;
      margin-bottom: 0;
    }

    &__filter {
      display: flex;
      align-items: center;
      padding: 0;

      img {
        margin-left: 10px;
        width: 12px;
        height: 12px;
      }
    }

    &__sorter {
      .dropdown-toggle {
        display: flex;
        align-items: center;
        &::after {
          content: none;
        }

        &:hover {
          cursor: pointer;
          h3 {
            text-decoration: underline;
          }
        }

        h3 {
          min-width: 90px;
          text-align: center;
        }

        img {
          width: 32px;
          height: 32px;
        }
      }

      .dropdown-menu {
        padding: 0;
        border-radius: 0;
        border: 0.5px solid $gray-222;

        .dropdown-item {
          width: 182px;
          height: 51px;
          display: flex;
          align-items: center;
          color: $gray-222;
          border-bottom: 1px solid $gray-ccc;
          cursor: pointer;

          &:hover {
            background-color: $white;
            text-decoration: underline;
          }
          &.active {
            background-color: $gray-f0efed;
            &:hover {
              background-color: $gray-f0efed;
            }
          }
        }
      }
    }
  }

  &__result {
    width: 100%;
    display: flex;
    align-items: start;
    position: sticky;
    z-index: 2;
    top: 52px;
    background-color: var(--bs-white);

    &--clearAll {
      flex: none;
      padding: 4px 15px 4px 0;
      font-weight: normal;
      text-decoration: underline;
    }

    &--selected {
      display: flex;
      padding: 0;
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: $gray-f0efed;
      span {
        padding: 5px 7px;
      }
      button {
        border: none;
        padding: 0;
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  &__filter {
    flex: 0;
    overflow: hidden;
    position: sticky;
    top: 52px;
    background-color: var(--bs-white);
    transform: translateX(-260px);
    transition: all 0.4s cubic-bezier(0.77, 0.7, 0.19, 1);
    visibility: hidden;

    &.filter-on {
      flex: 0 0 260px;
      padding-right: 20px;
      transform: translateX(0);
      visibility: visible;
      transition: all 0.4s cubic-bezier(0.77, 0.7, 0.19, 1);
    }
  }

  &__product {
    flex: 1 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -7px;

    &.filter-on {
      width: calc(100% - 260px);
    }

    &__item {
      width: 25%;
      padding: 0 7px;
      margin-bottom: 40px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__button {
    margin-top: 0;
    margin-bottom: 60px;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: 360px;
      min-height: 32px;
      background-color: $gray-222;
      color: $white;
      border: none;
    }
  }
}
