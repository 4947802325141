.banner-b6 {
  width: 100%;
  display: flex;
  position: relative;

  &__title {
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: baseline;
    color: $gray-333;
    font-weight: bold;
  }

  &__video {
    width: 100%;
    aspect-ratio: 700 / 662;
  }

  &__img {
    img {
      width: 100%;
      aspect-ratio: 700 / 662;
    }
  }

  &__text {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 20px 0;
  }
}
