.tabs {
  position: relative;
  z-index: 1;
  width: 100%;

  &:before {
    content: '';
    width: 100%;
    height: 70px;
    display: block;
  }

  &__container {
    width: 100%;
    box-shadow: 0 -1px 3px rgb(0 0 0 / 10%);
    background-color: var(--bs-white);
    position: fixed;
    bottom: 0;
    left: 0;

    &__item {
      text-align: center;
      padding: 8px 0;
      flex: 1;

      a {
        display: block;
        position: relative;

        .number {
          position: absolute;
          width: 18px;
          height: 18px;
          line-height: 18px;
          color: var(--bs-white);
          background-color: var(--bs-pink);
          border-radius: 50px;
          top: -2px;
          right: calc(50% - 20px);
        }
        img {
          width: 30px;
        }
        p {
          color: var(--bs-gray-333);
          margin-bottom: 0;
        }
      }

      &.active {
        p {
          color: var(--bs-pink);
        }
      }
    }
  }
}
