.product {
  .col {
    &--left {
      flex: 0;
    }
    &--mid {
      margin-left: 46px;
      width: 1000px;
      flex: 1 1 auto;
    }
    &--right {
      margin-left: 54px;
      width: 444px;
      flex: 1 0.7 auto;
    }
  }

  &__title {
    font-weight: bold;
    color: $gray-333;
  }

  &__name {
    font-weight: bold;
    color: $gray-333;
  }

  &__price {
    font-weight: bold;
    &[data-highlight='true'] {
      color: $red;
    }
  }

  &__original-price,
  &__point-price {
    color: $gray-222;
    font-weight: bold;
  }

  &__spec {
    &__title {
      line-height: 24px;
    }

    &__item {
      flex: 1 0 0;
      word-break: keep-all;
      padding: 7px;
      text-align: center;
      background-color: $gray-f0efed;
      border: 2px solid $gray-f0efed;
      cursor: pointer;

      &[data-active='true'] {
        border-color: $gray-666;
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      &.isSoldOut {
        background-color: $gray-666;
        border: 2px solid $gray-666;
        color: $white;
      }
    }
  }

  &__activity {
    p {
      margin-bottom: 6px;
    }
  }

  &__promotion {
    margin-bottom: 6px;
  }

  &__delivery {
    &__choose-shop {
      width: 100%;
      padding: 13px 8px;
      background-color: $gray-222;
      color: #fff;
    }
  }

  &__action {
    &__buy {
      width: 100%;
      padding: 13px 8px;
      background-color: $gray-222;
      border: 2px solid $gray-222;
      color: #fff;
      margin-bottom: 8px;
    }

    &__addCart {
      padding: 13px 8px;
      border: 2px solid $gray-222;
      color: $gray-222;
      background-color: #fff;
      flex: 1 1 auto;
      margin-bottom: 8px;

      &.isSoldOut {
        background-color: $gray-666;
        border: 2px solid $gray-666;
        color: $white;
      }
    }

    &__like {
      padding: 3px;
      width: 48px;
      height: 48px;
      border: 2px solid $gray-222;
      cursor: pointer;
      margin-left: 8px;
    }
  }

  .form-check {
    min-height: auto;
  }
}
