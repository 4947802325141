.child-category {
  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin-bottom: 0;
      margin-right: 20px;
      color: $gray-333;
      font-weight: unset;
    }
  }
}
