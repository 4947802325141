.banner-b11 {
  position: relative;
  width: 100%;
  color: $gray-333;

  &__title {
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: end;
    color: $gray-333;
    display: flex;
    padding-right: 100px;
    > *:first-child {
      font-weight: bold;
      flex: 0 0 auto;
    }
    > *:last-child {
      margin-left: 20px;
    }
  }

  &__action {
    display: flex;
    justify-content: end;
    margin-top: -30px;

    img {
      color: $gray-333;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  &__slider {
    overflow-x: hidden;
    margin: 10px auto 0;
    position: relative;
    width: 100%;

    .slider__track {
      display: flex;
      align-items: stretch;
      animation: scroll5 30s linear infinite;

      &:hover,
      &--pause {
        animation-play-state: paused;
      }

      &__item {
        img {
          aspect-ratio: 467 / 520;
        }
      }

      &__text {
        position: relative;

        > p {
          margin-bottom: 0;
        }
        &:before {
          content: '';
          display: block;
          width: 2px;
          height: 88px;
          background: #222;
          position: absolute;
          top: -88px;
          left: 30px;
        }
      }
    }
  }
}

@keyframes scroll5 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
