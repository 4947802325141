.categories {
  display: flex;
  width: 100%;
  position: relative;
  min-height: 100vh;

  $menuWidthPct: 20%;
  &__menu {
    width: $menuWidthPct;
    padding: 22px 32px;
    position: sticky;
    top: 0;
    height: 100vh;
    color: $gray-222;

    a:hover {
      text-decoration: underline;
    }

    h3 {
      margin-bottom: 15px;
      font-weight: bold;
    }

    &__item {
      margin-bottom: 13px;
      padding-bottom: 14px;
      &:not(:last-child) {
        border-bottom: 1px solid $gray-e6e7e9;
      }

      h4 {
        font-weight: bold;
      }
    }
  }

  &__content {
    width: calc(100% - #{$menuWidthPct});
  }
}
