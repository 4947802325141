.banner-b8 {
  color: $gray-333;
  width: 100%;

  &__title {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    h2 {
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__item {
      width: 48%;
      margin-bottom: 20px;

      p {
        width: 100%;
        text-align: center;
        margin-top: 10px;
      }

      img {
        width: 100%;
        aspect-ratio: 204 / 148;
      }
    }
  }
}
