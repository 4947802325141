.banner-b16 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  &__banner {
    flex-basis: 48%;
    img {
      width: 100%;
      aspect-ratio: 680 / 880;
    }
  }

  &__product {
    flex: 1;
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &__item {
      width: 48%;
    }
  }
}
