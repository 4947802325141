.banner-b15 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  &__banner {
    width: 48%;
    img {
      width: 100%;
      aspect-ratio: 680 / 440;
    }
  }

  &__product {
    &--left {
      width: 24%;
    }

    &--right {
      width: 24%;
    }
  }
}
