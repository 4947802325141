// mobile menu
.offcanvas {
  &-header .btn-close {
    margin: 0;
    padding: 0;
  }
  &-body {
    padding: 0;
  }
}

.menuAccordionMB {
  padding: 0;
  // 第一層
  .accordion {
    &-item {
      margin-left: 10px;
      border-width: 1px 0;

      &:not(:first-child) {
        border-top: 0;
      }
    }
    &-button {
      padding: 18px 0;
      font-weight: bold;
      position: relative;

      &:not(.collapsed) {
        color: var(--bs-gray-333);
        background-color: var(--bs-white);
        box-shadow: inset 0 -1px 0 var(--bs-gray-d8d8d8);
        border-bottom: 1px solid var(--bs-gray-d8d8d8);
      }

      &:focus {
        box-shadow: none;
      }

      &:after {
        position: absolute;
        right: 0;
        top: 4px;
        width: 48px;
        height: 48px;
        background-position: center;
      }
    }
    &-body {
      padding: 0;

      // 第二層
      .accordion {
        &-item {
          margin-left: 0px;
          border-top: 0;
        }
        &-button {
          font-weight: normal;
          padding-left: 10px;
        }

        &-body {
          // 第三層
          .menuAccordionMB__item {
            background-color: var(--bs-gray-f7f7f7);
            border-color: var(--bs-white);
          }
        }
      }

      .menuAccordionMB__item {
        display: block;
        margin-left: 0;
        font-weight: normal;
        padding-left: 10px;

        &:first-child {
          border-top-color: var(--bs-gray-d8d8d8);
        }
      }
    }

    &-collapse {
      margin-top: -1px;
    }
  }

  &__item {
    display: block;
    margin-left: 10px;
    border: 1px solid var(--bs-gray-d8d8d8);
    border-width: 1px 0;
    padding: 16px 0;
    font-weight: bold;
    &:not(:first-child) {
      border-top: 0;
    }
  }

  &__content {
    display: block;
    margin: 16px 10px 16px 0;

    img {
      margin-bottom: 16px !important;
    }
  }
}
