.recent-view {
  width: 100%;

  &__title {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: baseline;
    color: $gray-333;
    display: flex;
    h2 {
      font-weight: bold;
    }
  }

  &__container {
    position: relative;

    &__action {
      $actionIconWidth: 48px;

      @mixin arrow($isPrev) {
        position: absolute;
        z-index: 1;
        top: 30%;
        width: $actionIconWidth;
        height: $actionIconWidth;
        cursor: pointer;

        @if $isPrev == true {
          left: 0;
          @include media-breakpoint-up(xxl) {
            left: -($actionIconWidth / 2);
          }
        } @else {
          right: 0;
          @include media-breakpoint-up(xxl) {
            right: -($actionIconWidth / 2);
          }
        }

        img {
          img {
            width: 100%;
            aspect-ratio: 1 / 1;
          }
        }
      }

      &--prev {
        @include arrow(true);
      }

      &--next {
        @include arrow(false);
      }
    }

    &__slider {
      margin-top: 10px;
      margin-left: -2px;
      width: calc(100% + 4px);

      .slick-slide > div {
        margin: 0 6px;
      }
      .slick-list {
        margin: 0 -6px;
      }

      a {
        width: 100%;
        position: relative;
        // padding: 0 2px;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
