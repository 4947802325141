.sku-image-slider {
  &__item {
    width: 72px;
    height: 72px;
    padding: 2px;
    border: 2px solid transparent;
    margin-bottom: 8px;
    cursor: pointer;

    &[data-active='true'] {
      border-color: $gray-666;
    }

    &__ar {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray-d8d8d8;
      img {
        width: 40px;
        aspect-ratio: 1 / 1;
      }
    }
  }
}
