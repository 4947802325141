.selectBox {
  display: flex;
  align-items: center;

  .sr-only {
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
  }

  &__quantity {
    width: 40px;
    height: 40px;
    background-color: $gray-f0efed;
    border: none;
    position: relative;
    padding: 0;

    &:focus::before {
      display: block;
      content: '';
      border-radius: 0;
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      border: 1px solid #666;
    }
  }

  &__input {
    width: 40px;
    height: 40px;
    padding: 5px;
    border: 1px solid $gray-666;
    border-radius: 0;
    text-align: center;

    &:focus-visible {
      outline: none;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:focus {
      -webkit-box-shadow: 0 0 0 1px #fff inset, 0 0 0 2px $gray-666 inset;
      box-shadow: 0 0 0 1px #fff inset, 0 0 0 2px $gray-666 inset;
    }
  }
}
