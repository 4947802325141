.home {
  .cover {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: pre-wrap;

    &--top {
      top: 15%;
      left: 50%;
      transform: translateX(-50%);
      button {
        width: 200px;
        height: 40px;
        color: $white;
        background-color: $gray-333;
        border: none;
      }
    }

    &--center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      button {
        width: 120px;
        height: 40px;
        color: $white;
        background-color: $gray-333;
        border: none;
      }
    }

    &--left-bottom {
      bottom: 10%;
      left: 5%;
      color: $dark;
      flex-direction: row;
      padding: 0;
      background-color: $white;
      border-radius: 30px;

      span {
        width: 0;
        overflow: hidden;
        transition: width 2s;
      }

      img {
        width: 20px;
      }

      @keyframes Expand {
        0% {
          width: 0;
          opacity: 0;
        }
        70% {
          width: 180px;
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      &[data-expand='true'] {
        padding: 10px 30px;
        span {
          animation-name: Expand;
          animation-duration: 1.6s;
          width: 180px;
          margin-left: 10px;
          white-space: nowrap;
        }
      }
    }
  }

  .accessories {
    display: flex;
    flex-direction: column;

    &__title {
      width: 100%;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__img {
      width: 260px;
      height: 260px;
      border-radius: 50%;
      overflow: hidden;
    }

    h2 {
      width: 400px;
    }
  }

  .customTitle {
    align-items: end !important;

    > div:first-child {
      flex: 0 0 auto;
    }
  }

  .swipe-card-title {
    padding-right: 100px;
    align-items: end !important;

    > div:first-child {
      flex: 0 0 auto;
    }
  }

  .home-swiper {
    position: relative;
    width: 100%;

    &__action {
      display: flex;
      justify-content: end;
      margin-top: -50px;

      &--prev,
      &--next {
        color: $gray-333;
        width: 48px;
        height: 48px;
        cursor: pointer;
      }
    }
  }
}
