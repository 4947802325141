.banner-b2 {
  width: 100%;
  position: relative;

  &__title {
    margin-bottom: 20px;
    padding: 0 20px;
    width: 100%;
    font-weight: bold;
    display: flex;
    align-items: baseline;
  }
}
