$designerImgWidth: 210px;

.banner-b7 {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: $gray-333;
  &__ad {
    width: 100%;
    position: relative;
    padding-bottom: calc(#{$designerImgWidth} / 2);

    &__banner {
      width: 100%;
      aspect-ratio: 428 / 526;
    }

    &__designer {
      position: absolute;
      left: 50%;
      bottom: calc(-#{$designerImgWidth} / 2);
      transform: translate(-50%, calc(-#{$designerImgWidth} / 2));
      z-index: 1;
      width: $designerImgWidth;
      height: $designerImgWidth;
      background-color: $white;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        aspect-ratio: 1 / 1;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }

  &__title {
    width: 100%;
    min-height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  &__description {
    width: 100%;
    text-align: center;
    padding: 0 20px;
  }
}
