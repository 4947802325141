.sku-color-slider {
  &__container {
    gap: 12px;
  }

  &__item {
    width: 52px;
    height: 52px;
    padding: 2px;
    border: 2px solid $gray-ccc;
    cursor: pointer;

    &[data-active='true'] {
      border-color: $gray-666;
    }
  }
}
