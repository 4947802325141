.banner-b4 {
  width: 100%;

  &__title {
    margin-bottom: 10px;
    font-weight: bold;
    color: $gray-333;
  }

  &__img {
    display: block;
    width: 100%;
    margin-bottom: 33px;
    img {
      width: 100%;
      aspect-ratio: 1400 / 400;
    }
  }

  &__info {
    width: 100%;
    display: flex;
    align-items: start;

    h2 {
      width: 100%;
      font-weight: bold;
      color: $gray-333;
    }

    h3 {
      color: $gray-333;
    }

    &__title,
    &__desc,
    &__material {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    &__title {
      padding-right: 17px;
      h3 {
        line-height: 1.8;
      }
    }

    &__desc {
      min-height: 278px;
      padding-right: 17px;

      p {
        line-height: 1.8;
        margin-bottom: 0;
      }
    }

    &__material {
      display: flex;
      &--item {
        flex: 1 auto;
        img {
          width: 100%;
          aspect-ratio: 337/101;
        }
      }
      &--more {
        width: 25%;
        background-color: $gray-d8d8d8;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
