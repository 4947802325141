.banner-b7 {
  width: 100%;

  &__ad {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    &__banner {
      width: 100%;
      aspect-ratio: 1400 / 818;
    }
  }

  &__designer {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    &__title {
      width: 100%;
      min-height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 20px;
    }

    &__description {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: nowrap;

      &--left {
        width: 28%;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        text-align: end;
      }

      &__img {
        width: 18%;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        overflow: hidden;
      }

      &--right {
        width: 28%;
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }
    }
  }
}
