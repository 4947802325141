.sku-add-on-selector {
  width: 100%;
  button {
    width: 100%;
    height: 40px;
    border-radius: 0;
    background-color: $gray-f0efed;
    border: none;
    color: $gray-222;
    padding: 0 0 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    &::after {
      content: unset;
    }

    &:focus {
      box-shadow: none;
    }

    img {
      width: 40px;
      height: 32px;
    }

    &[aria-expanded='true'] {
      img {
        transform: rotate(180deg);
      }
    }
  }
  .dropdown-menu {
    width: 100%;
    transform: translateY(32px) !important;
    border-radius: 0;
    background-color: $gray-f0efed;
    border: none;
    padding: 0;

    .dropdown-item {
      height: 40px;
      text-decoration: underline;
      padding-left: 10px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: unset;
      }

      &:active,
      &:focus {
        color: $gray-222;
      }
    }
  }
}
