.banner-b5 {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  &__title {
    width: 100%;
    margin-bottom: 8px;
    color: $gray-333;
    font-weight: bold;
    text-align: center;
  }

  &__img {
    margin-bottom: 10px;
    img {
      width: 100%;
      aspect-ratio: 1 / 1;
    }
  }

  &__subtitle {
    display: block;
    letter-spacing: 3px;
    padding: 0 18px;
  }
}
