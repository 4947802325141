.banner-b4 {
  width: 100%;

  &__title {
    font-weight: bold;
    display: block;
    color: $gray-333;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  &__img {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    img {
      width: 100%;
      aspect-ratio: 428 / 480;
    }
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    h2 {
      width: 100%;
      font-weight: bold;
      color: $gray-333;
      text-align: center;
    }

    h3 {
      line-height: 1.5;
      color: $gray-333;
      padding: 0 20px;
    }

    &__material {
      display: flex;
      margin-bottom: 10px;
      &--item {
        flex: 1 auto;
        img {
          width: 100%;
          aspect-ratio: 4/1;
        }
      }
      img {
        width: 100%;
      }
      &--more {
        width: 25%;
        aspect-ratio: 108 / 80;
        background-color: $gray-d8d8d8;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
