.sku-add-on {
  color: gray-333;
  &__title {
    margin-bottom: 19px;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0 23px;
  }

  .add-on-item {
    flex-basis: 45%;
    flex-grow: 1;
    padding-top: 9px;
    padding-bottom: 19px;
    height: 255px;
    display: flex;

    .form-check {
      padding-left: 0;
      margin: 5px (5px + 14px) 5px 5px;
      min-width: 16px;

      .form-check-input {
        width: 16px;
        height: 16px;
        border-radius: 0;
        border: 1px solid $gray-222;
        background-color: $white;
        margin-left: 0;
        &:checked {
          background-color: $gray-222;
          color: $white;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
      }
    }
    &__product {
      display: flex;
      flex-wrap: nowrap;

      &__img {
        position: relative;
        flex-basis: 150px;
        aspect-ratio: 1 / 1;
        margin-right: 8px;
        flex-shrink: 1;
        &__thumbnail {
          width: 100%;
          aspect-ratio: 1 / 1;
          object-fit: cover;
        }

        &__icon {
          position: absolute;
          top: 0;
          right: 0;
          width: 24%;
          max-width: 36px;
          aspect-ratio: 1 / 1;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-shrink: 1;
        flex-basis: 272px;
        h2 {
          color: $gray-222;
          margin-bottom: 6px;
          min-height: 28px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        h3 {
          color: $gray-333;
          margin-bottom: 7px;
        }

        &__discount {
          font-weight: bold;
          line-height: 16px;
          color: $red;
          margin-bottom: 2px;
          span {
            margin-right: 5px;
          }
        }

        &__color {
          width: calc(100% / 3 - 12px * 2 / 3);
          max-width: 40px;
          height: unset;
          aspect-ratio: 1 / 1;
        }
      }
    }

    &__spec {
      margin-top: 12px;
      min-height: 24px;
      display: flex;
      align-items: center;
    }

    &__option {
      width: 100%;
      display: flex;

      &__spec {
        flex-basis: 70%;
        margin-right: 8px;
      }

      &__amount {
        flex-grow: 1;
        height: 40px;
      }
    }
  }
}
