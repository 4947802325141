$scrollBarWidth: 17px;

$gray-222: #222222;
$gray-323232: #323232;
$gray-333: #333333;
$gray-666: #666666;
$gray-ccc: #cccccc;
$gray-d8d8d8: #d8d8d8;
$gray-f4f4f4: #f4f4f4;
$gray-f7f7f7: #f7f7f7;
$gray-f0efed: #f0efed;
$gray-e6e7e9: #e6e7e9;
$white: #ffffff;
$dark: #000000;
$pink: #ff5960;
$red: #bb0000;

$grays: (
  '222': $gray-222,
  '323232': $gray-323232,
  '333': $gray-333,
  '666': $gray-666,
  'ccc': $gray-ccc,
  'd8d8d8': $gray-d8d8d8,
  'f4f4f4': $gray-f4f4f4,
  'f7f7f7': $gray-f7f7f7,
  'f0efed': $gray-f0efed,
);

$border-width: 1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  10: 10px,
);

$font-size-base: 1rem;
$font-sizes: (
  12: $font-size-base * 0.75,
  13: $font-size-base * 0.813,
  14: $font-size-base * 0.875,
  16: $font-size-base,
  20: $font-size-base * 1.25,
  30: $font-size-base * 1.875,
  34: $font-size-base * 2.125,
  42: $font-size-base * 2.625,
  60: $font-size-base * 3.75,
);

$spacer: 1rem;
$spacers: (
  0: 0,
  2: (
    $spacer * 0.125,
  ),
  4: (
    $spacer * 0.25,
  ),
  5: (
    $spacer * 0.313,
  ),
  6: (
    $spacer * 0.375,
  ),
  8: (
    $spacer * 0.5,
  ),
  10: (
    $spacer * 0.625,
  ),
  12: (
    $spacer * 0.75,
  ),
  14: (
    $spacer * 0.875,
  ),
  15: (
    $spacer * 0.938,
  ),
  16: (
    $spacer * 1,
  ),
  18: (
    $spacer * 1.125,
  ),
  20: (
    $spacer * 1.25,
  ),
  22: (
    $spacer * 1.375,
  ),
  24: (
    $spacer * 1.5,
  ),
  28: (
    $spacer * 1.75,
  ),
  30: (
    $spacer * 1.875,
  ),
  35: (
    $spacer * 2.188,
  ),
  36: (
    $spacer * 2.25,
  ),
  40: (
    $spacer * 2.5,
  ),
  42: (
    $spacer * 2.625,
  ),
  44: (
    $spacer * 2.75,
  ),
  56: (
    $spacer * 3.5,
  ),
  60: (
    $spacer * 3.75,
  ),
  64: (
    $spacer * 4,
  ),
  80: (
    $spacer * 5,
  ),
  92: (
    $spacer * 5.75,
  ),
  100: (
    100px,
  ),
  116: (
    116px,
  ),
);

// custom bootstrap breakpoint
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1820px,
  //1680 + 70 + 70
);

$container-max-widths: (
  sm: 600px,
  md: 800px,
  lg: 1024px,
  xl: 1220px,
  xxl: 1820px,
  //1680 + 70 + 70
);

.container-xxl {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

// close button
$btn-close-width: 36px;
$btn-close-height: $btn-close-width;
$btn-close-padding-x: 0;
$btn-close-padding-y: $btn-close-padding-x;
$btn-close-color: $white;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 0.75;
$btn-close-focus-opacity: 0.5;

$breadcrumb-divider: quote('>');

//Generating All Shades of Gray
@each $key, $value in $grays {
  .bg-gray-#{$key} {
    background-color: $value !important;
  }

  .text-gray-#{$key} {
    color: $value !important;
  }
}
$form-check-input-checked-color: $gray-222;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-check-input-checked-color}'/></svg>");
