.banner-b18 {
  width: 100%;
  color: $gray-333;

  &__title {
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: baseline;
    color: $gray-333;
    h2 {
      font-weight: bold;
      margin-right: 10px;
    }

    span {
      margin-left: 17px;
      font-weight: normal;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__product {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 0 14px;

    &__item {
      flex-basis: calc(25% - 14px);
      flex-grow: 1;
    }
  }
}
