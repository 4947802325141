.sku-combo {
  color: $gray-333;
  width: 100%;
  display: flex;
  gap: 10px;

  @mixin skuComboImage() {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &--large {
    position: relative;
    flex: 2;
    aspect-ratio: 1 / 1;
    @include skuComboImage;
  }

  &--medium {
    position: relative;
    flex: 1;
    aspect-ratio: 194 / 396;
    @include skuComboImage;
  }

  &--small {
    display: flex;
    flex-direction: column;
    flex: 1;
    aspect-ratio: 194 / 396;
    gap: 10px;

    &__item {
      position: relative;
      width: 100%;
      flex: 1;
      @include skuComboImage;
    }
  }

  &__plan {
    &__title {
      width: 100%;
      height: 100%;
      background-color: $gray-d8d8d8;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        margin-bottom: 40px;
        opacity: 0.36;
        font-weight: bold;
      }

      p {
        white-space: pre-wrap;
        text-align: end;
        margin-bottom: 0;
      }
    }

    &__description {
      width: 100%;
      height: 100%;
      background-color: $gray-222;
      padding: 20px;
      display: flex;
      align-items: flex-end;
      p {
        white-space: pre-wrap;
        color: $white;
        opacity: 0.36;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }

  &__hover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    cursor: pointer;

    &:hover {
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.8);
      }

      .sku-combo__hover__cover {
        opacity: 1;
      }
    }

    &__cover {
      width: 100%;
      height: 100%;
      padding: 10px;
      position: absolute;
      z-index: 1;
      color: $gray-222;
      transition: opacity 0.3s ease-out;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0;

      h2,
      h3 {
        text-align: center;
      }

      h2 {
        text-decoration: underline;
        margin-bottom: 10px;
      }
    }
  }
}
