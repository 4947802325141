.banner-b9 {
  color: $gray-333;
  width: 100%;

  &__title {
    margin-bottom: 17px;
    width: 100%;
    font-weight: bold;
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__item {
      width: 32%;

      p {
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }

      img {
        width: 100%;
        aspect-ratio: 440 / 520;
      }
    }
  }
}
