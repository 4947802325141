.video-card {
  width: 100%;
  height: 100%;
  object-fit: initial;

  &__cover {
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--bs-white);
    white-space: pre-wrap;
    h1 {
      line-height: 90%;
      margin-bottom: 20px;
    }
  }

  &__banner {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 90px;
    width: 138px;
    height: 185px;
  }

  &__action {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 30px;
    height: 30px;
  }
}
