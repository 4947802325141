.fixed-tool {
  &--pc,
  &--mb {
    position: fixed;
    z-index: 1;
    right: 0;
    bottom: 10vh;
  }

  &--pc {
    width: 66px;
  }
  &--mb {
    width: 48px;
  }

  &__scrollToTop,
  &__chat {
    border: none;
    padding: 0;
    img {
      width: 100%;
    }
  }
}
