.banner-b3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  &__title {
    margin-bottom: 10px;
    width: 100%;
    font-weight: bold;
    display: flex;
    align-items: baseline;
  }

  img {
    width: 100%;
    aspect-ratio: 1400 / 608;
  }

  &__text {
    display: block;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 20px 0;
  }
}
