.banner-b16 {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  &__banner {
    width: 100%;
    margin-bottom: 10px;
    img {
      width: 100%;
      aspect-ratio: 428/227;
    }
  }

  &__product {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    &__item {
      width: 46%;
    }
  }
}
