.banner-b6 {
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  .col-12:first-child {
    margin-bottom: 40px;
  }

  &__title {
    padding-left: 20px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: baseline;
    color: $gray-333;
    font-weight: bold;
  }

  &__video {
    width: 100%;
    aspect-ratio: 1 / 1;
  }

  &__text {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 20px 0;
  }
}
