.sku-add-on {
  color: $gray-333;
  padding: 0 20px;

  &__title {
    min-height: 24px;
    display: flex;
    align-items: center;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0 23px;
  }

  .add-on-item {
    flex-grow: 1;
    padding-bottom: 8px;
    height: fit-content;
    display: flex;

    .form-check {
      padding-left: 0;
      margin: 0 5px 0 0;
      min-width: 26px;

      .form-check-input {
        width: 16px;
        height: 16px;
        border-radius: 0;
        border: 1px solid $gray-222;
        background-color: $white;
        margin-left: 2px;
        &:checked {
          background-color: $gray-222;
          color: $white;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
      }
    }

    &--detail {
      padding: 0;
      text-align: inherit;
      &:focus {
        box-shadow: none;
      }
    }

    &__product {
      display: flex;
      flex-wrap: nowrap;

      &__img {
        position: relative;
        width: 20.205%;
        min-width: 80px;
        // height: 80px;
        margin-right: 5px;
        flex: none;

        &__thumbnail {
          // width: inherit;
          // height: inherit;
          object-fit: cover;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        // height: 80px;
        justify-content: space-between;

        h2 {
          color: $gray-222;
          margin-bottom: 6px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

        &__discount {
          font-weight: bold;
          color: $red;
          span {
            margin-right: 5px;
          }
        }
      }
    }

    &__spec {
      min-height: 24px;
      display: flex;
      align-items: center;
    }
  }
}
