.suggest-category {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: $gray-333;

  &__category {
    width: 50%;
    margin-bottom: 2px;

    p {
      font-weight: bold;
      min-height: 30px;
      display: flex;
      align-items: center;
      margin-bottom: 1px;
    }

    li {
      text-decoration: underline;
      min-height: 28px;
      display: flex;
      align-items: center;
    }
  }
}
