.related-category {
  color: $gray-333;
  h2 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;

    &__item {
      margin: 0 15px 10px 0;
      padding: 15px 30px;
      background-color: $gray-f0efed;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
