.product-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  color: $gray-222;

  &__thumbnail {
    width: 100%;
    padding-bottom: 100%;
    margin-bottom: 14.5px;
    position: relative;
    background-color: $gray-ccc;

    &__image {
      aspect-ratio: 1 / 1;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    &__like {
      width: 36px;
      aspect-ratio: 1 / 1;
      position: absolute;
      z-index: 1;
      top: 5px;
      right: 5px;
    }
  }

  &__material {
    width: 100%;
    margin-bottom: 14px;
    padding: 0 6px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;

    &__item {
      flex-grow: 1;
      max-width: 45px;
      margin: 0 1px;

      span {
        width: 100%;
        max-width: 32px;
        padding: 2px;
        border: 1px solid $gray-ccc;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: transparent;

        &[data-active='true'] {
          padding: 1px;
          border: 2px solid $gray-666;
        }
      }

      img {
        width: 100%;
        max-width: 26px;
        border-radius: 50%;
      }
    }

    &__more {
      color: $gray-222;
      flex-basis: 30%;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  &__title {
    padding: 0 6px;
    margin-bottom: 6px;
  }

  &__name {
    padding: 0 6px;
    margin-bottom: 4px;
    a {
      line-height: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  &__price {
    padding: 0 6px;
    font-weight: bold;
    margin-bottom: 6px;
    line-height: 16px;

    &[data-highlight='true'] {
      color: $red;
    }
  }

  &__original-price {
    padding: 0 6px;
    color: $gray-666;
    margin-bottom: 2px;
  }

  &__activity {
    padding: 0 6px;
    color: $gray-666;
    line-height: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
