.banner-b11 {
  position: relative;
  width: 100%;
  color: $gray-333;

  &__title {
    margin-bottom: 8px;
    padding: 0 20px;
    width: 100%;
    display: flex;
    color: $gray-333;
    display: flex;
    flex-direction: column-reverse;
    > *:first-child {
      font-weight: bold;
      flex: 0 0 auto;
    }
  }

  &__slider {
    margin-top: 10px;
    margin-left: 20px;

    &__text {
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 2px;
        height: 44px;
        background: #222;
        position: absolute;
        top: -40px;
        left: 30px;
      }
    }
  }
}
