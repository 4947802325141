.filter-accordion {
  color: $gray-333;

  &__filter-title {
    width: 100%;
    min-height: 70px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .accordion-item {
    border: none;
    margin-bottom: 4px;

    .accordion-button {
      width: 100%;
      height: 70px;
      padding: 0 0 0 20px;
      background-color: $gray-f0efed;
      border: none;
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $gray-333;
      font-weight: bold;

      &:focus {
        border: none;
        box-shadow: none;
      }

      &::after {
        content: none;
      }

      img {
        width: 48px;
        height: 48px;
      }
    }

    .accordion-body {
      padding: 16px 40px;
      .list-group {
        .list-group-item {
          border: none;
          display: flex;
          align-items: center;
          padding: 0;
          margin-bottom: 10px;

          input {
            width: 16px;
            height: 16px;
            margin-right: 6px;
            border: 2px solid $gray-222;
            &:checked {
              background-color: $gray-222;
              border-color: $gray-222;
            }

            &:focus {
              box-shadow: none;
              outline: 2px solid $gray-222;
              outline-offset: 1px;
            }
          }

          .checkbox {
            border-radius: 0;
          }
        }
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
