.sku-combo {
  padding: 0 20px;
  color: $gray-333;
  width: 100%;
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  @mixin skuComboImage() {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &--large {
    position: relative;
    min-width: 340px;
    max-width: 340px;
    aspect-ratio: 1 / 1;
    @include skuComboImage;
  }

  &--medium {
    position: relative;
    min-width: 168px;
    max-width: 168px;
    aspect-ratio: 194 / 396;
    @include skuComboImage;
  }

  &--small {
    display: flex;
    flex-direction: column;
    min-width: 168px;
    max-width: 168px;
    aspect-ratio: 194 / 396;
    gap: 10px;

    &__item {
      position: relative;
      width: 100%;
      flex: 1;
      @include skuComboImage;
    }
  }

  &__plan {
    &__description {
      width: 100%;
      height: 100%;
      background-color: $gray-222;
      padding: 9px 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      h2 {
        color: $white;
        opacity: 0.36;
        margin-bottom: 0;
      }
      p {
        white-space: pre-wrap;
        color: $white;
        opacity: 0.36;
        font-weight: bold;
        margin-bottom: 0;
        text-align: end;
      }
    }
  }
}
