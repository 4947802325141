.banner-b10 {
  position: relative;
  width: 100%;
  color: $gray-333;

  &__title {
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: end;
    color: $gray-333;
    display: flex;
    padding-right: 100px;
    > *:first-child {
      font-weight: bold;
      flex: 0 0 auto;
    }
    > *:last-child {
      margin-left: 20px;
    }
  }

  &__action {
    display: flex;
    justify-content: end;
    margin-top: -50px;

    &--prev,
    &--next {
      color: $gray-333;
      width: 48px;
      height: 48px;
      cursor: pointer;
    }
  }

  &__slider {
    a {
      display: block;
      width: fit-content;
      width: 100%;
    }

    img {
      width: 100%;
      aspect-ratio: 1 / 1;
    }

    h2 {
      width: fit-content;
    }
  }
}
