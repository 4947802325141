.customBreadcrumb {
  min-height: 50px;
  display: flex;
  align-items: center;
  ol {
    margin-bottom: 0;
    padding-left: 10px;
    li {
      text-decoration: underline;
    }
  }
}
