a,
p,
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  line-height: 1.5;
}

a {
  color: $gray-222;
  text-decoration: none;
  &:hover {
    color: inherit;
  }
}

img,
svg {
  width: 100%;
  height: auto;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

// public
.lt-3 {
  letter-spacing: 3px;
}

.cur-p {
  cursor: pointer;
}
