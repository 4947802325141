.banner-b19 {
  width: 100%;

  &__title {
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: baseline;
    color: $gray-333;
    h2 {
      font-weight: bold;
      margin-right: 10px;
    }

    &__subtitle {
      margin-left: 17px;
      font-weight: normal;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__container {
    position: relative;

    &__action {
      $actionIconWidth: 48px;

      @mixin arrow($isPrev) {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        width: $actionIconWidth;
        height: $actionIconWidth;
        cursor: pointer;

        @if $isPrev == true {
          left: 0;
          @include media-breakpoint-up(xxl) {
            left: -($actionIconWidth / 2);
          }
        } @else {
          right: 0;
          @include media-breakpoint-up(xxl) {
            right: -($actionIconWidth / 2);
          }
        }

        img {
          img {
            width: 100%;
            aspect-ratio: 1 / 1;
          }
        }
      }

      &--prev {
        @include arrow(true);
      }

      &--next {
        @include arrow(false);
      }
    }

    &__slider {
      margin-top: 10px;
      margin-left: -6px;
      width: calc(100% + 12px);

      a {
        width: 100%;
        position: relative;
        padding: 0 6px;
      }

      &__hover {
        &:hover {
          &::after {
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            background-color: rgba(255, 255, 255, 0.8);
          }

          .banner-b19__container__slider__cover {
            opacity: 1;
          }
        }
      }

      &__cover {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        color: $gray-222;
        transition: opacity 0.3s ease-out;

        h3,
        h4,
        h5 {
          text-align: center;
        }

        h4 {
          margin-bottom: 10px;
          text-decoration: underline;
        }
      }

      img {
        width: 100%;
        aspect-ratio: 1 / 1;
      }

      &__more {
        width: 100%;
        aspect-ratio: 1 / 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray-f7f7f7;
        text-decoration: underline;
      }
    }
  }
}
