.sku-also-viewed {
  color: $gray-222;

  &__title {
    font-weight: bold;
    min-height: 64px;
    display: flex;
    align-items: center;
  }

  &__slider {
    &__item {
      position: relative;
      cursor: pointer;

      img {
        aspect-ratio: 1 / 1;
      }

      a {
        display: block;
      }

      &:hover {
        &::after {
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.8);
        }

        .sku-also-viewed__slider__item__cover {
          opacity: 1;
        }
      }

      &__cover {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        color: $gray-222;
        transition: opacity 0.3s ease-out;

        h2,
        h3 {
          text-align: center;
        }

        h2 {
          text-decoration: underline;
          margin-bottom: 10px;
        }
      }
    }
  }
}
