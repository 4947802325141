.filter-accordion {
  color: $gray-333;

  .accordion-item {
    border: none;
    margin-bottom: 4px;

    .accordion-button {
      width: 100%;
      height: 70px;
      padding: 0 0 0 20px;
      background-color: $gray-f0efed;
      border: none;
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $gray-333;

      &:focus {
        border: none;
        box-shadow: none;
      }

      &::after {
        content: none;
      }

      img {
        width: 48px;
        height: 48px;
      }
    }

    .accordion-body {
      padding: 16px 20px;
      .list-group {
        .list-group-item {
          border: none;
          display: flex;
          align-items: center;
          padding: 0;
          margin-bottom: 10px;

          .checkbox {
            border-radius: 0;
            width: 26px;
            height: 26px;
            margin-right: 13px;
            border: 3px solid $gray-222;

            &:checked {
              background-color: $gray-222;
              border-color: $gray-222;
            }

            &:focus {
              box-shadow: none;
              outline: 3px solid $gray-222;
              outline-offset: 2px;
            }
          }
        }
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
