@import 'bootstrap/scss/bootstrap';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/utilities';

$utilities: map-merge(
  $utilities,
  (
    'width':
      map-merge(
        map-get($utilities, 'width'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'width'), 'values'),
              (
                90: 90%,
              )
            ),
        )
      ),
  )
);

.form-check {
  &-input {
    background-color: $gray-d8d8d8;
    border-color: $gray-d8d8d8;
    &:focus {
      border-color: $gray-d8d8d8;
      box-shadow: none;
    }
    &:checked[type='radio'] {
      background-color: $gray-d8d8d8;
      border-color: $gray-d8d8d8;
    }
  }
}
