.banner-b2 {
  width: 100%;
  position: relative;

  &__title {
    margin-bottom: 40px;
    width: 100%;
    font-weight: bold;
    display: flex;
    align-items: baseline;
    padding-left: 10px;
  }

  img {
    width: 100%;
    aspect-ratio: 1400 / 723;
  }
}
