@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin fadeIn() {
  animation-name: FadeIn;
  animation-duration: 1s;
  animation-timing-function: ease;
}

@keyframes FadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@mixin fadeOut() {
  animation-name: FadeOut;
  animation-duration: 1s;
  animation-timing-function: ease;
}

@keyframes FadeInLeft {
  0% {
    transform: translateX(12px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@mixin fadeInLeft() {
  animation-name: FadeInLeft;
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.25, 1, 0.3, 1);
}
