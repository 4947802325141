.product-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  color: $gray-222;

  &__thumbnail {
    width: 100%;
    padding-bottom: 100%;
    margin-bottom: 15px;
    position: relative;
    background-color: $gray-ccc;

    &__image {
      aspect-ratio: 1 / 1;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    &__like {
      width: 36px;
      aspect-ratio: 1 / 1;
      position: absolute;
      z-index: 2;
      top: 10px;
      right: 10px;
    }
  }

  &__material {
    padding: 0 16px;
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;

    &__item {
      flex-grow: 1;
      max-width: 49px;
      margin: 0 1px;

      span {
        width: 100%;
        max-width: 36px;
        padding: 2px;
        border: 1px solid $gray-ccc;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: transparent;

        &[data-active='true'] {
          padding: 1px;
          border: 2px solid $gray-666;
        }
      }

      img {
        width: 100%;
        max-width: 30px;
        border-radius: 50%;
      }
    }

    &__more {
      color: $gray-222;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  &__title {
    padding: 0 16px;
    margin-bottom: 12px;
  }

  &__name {
    padding: 0 16px;
    margin-bottom: 12px;
    padding-right: 30px;
    a {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  &__price {
    padding: 0 16px;
    font-weight: bold;

    &[data-highlight='true'] {
      color: $red;
    }
  }

  &__original-price {
    padding: 0 16px;
    min-height: 24px;
    display: flex;
    align-items: center;
    color: $gray-666;
  }

  &__activity {
    padding: 4px 16px 0;
    display: flex;
    align-items: center;
    color: $gray-666;
  }
}
