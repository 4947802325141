.banner-b5 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  &__title {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: end;
    color: $gray-333;
    display: flex;
    > *:first-child {
      font-weight: bold;
      flex: 0 0 auto;
    }
  }

  &__img {
    width: 100%;
    display: flex;
    img {
      width: 100%;
      aspect-ratio: 700 / 794;
    }
  }

  &__text {
    display: block;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 20px 0;
  }
}
