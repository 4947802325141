.productFilter {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__action {
    position: sticky;
    z-index: 3;
    top: 0;
    padding: 10px 0;
    background-color: var(--bs-white);

    &__filter {
      display: flex;
      align-items: center;
      padding: 0;
      width: 95%;
      margin: 0 auto;

      img {
        margin-left: 10px;
        width: 12px;
        height: 12px;
      }
    }

    &__offcanvas {
      $footerHeight: 100px;

      width: 100%;
      padding-bottom: $footerHeight;

      .offcanvas-header {
        padding: 0;
      }
      &__button {
        padding: 0;
      }

      &__footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: $footerHeight;
        background-color: $white;
        border-top: 2px solid $gray-222;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          width: 35%;
          height: 60px;
          border: none;
        }

        &--clear {
          background-color: $gray-f0efed;
          color: $gray-222;
          margin-right: 20px;
        }

        &--ensure {
          background-color: $gray-222;
          color: $white;
        }
      }
    }
  }

  &__result {
    width: 95%;
    display: flex;
    align-items: start;
    position: sticky;
    z-index: 2;
    top: 46px;
    background-color: var(--bs-white);

    &--clearAll {
      flex: none;
      padding: 4px 15px 4px 0;
      font-weight: normal;
      text-decoration: underline;
    }

    &--selected {
      display: flex;
      padding: 0;
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: $gray-f0efed;
      span {
        padding: 5px 7px;
      }
      button {
        border: none;
        padding: 0;
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  &__product {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__item {
      width: 49.4%;
    }
  }

  &__button {
    margin: 20px auto 60px;
    width: 90%;
    display: flex;
    justify-content: center;

    button {
      padding: 0 20px;
      min-height: 32px;
      background-color: $gray-222;
      color: $white;
      border: none;
    }
  }
}
