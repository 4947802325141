.recent-view {
  width: 100%;

  &__title {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: baseline;
    color: $gray-333;
    display: flex;
    padding-left: 20px;
    h2 {
      font-weight: bold;
    }
  }

  &__container {
    position: relative;

    &__slider {
      margin-top: 10px;
      padding-left: 20px;

      a {
        width: 100%;
        position: relative;
        padding-right: 8px;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
