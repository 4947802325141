.sku-tabs {
  .accordion-item {
    border: none;
    .accordion-button {
      height: 70px;
      padding: 0 20px;
      background-color: $gray-f0efed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      color: $gray-333;
      box-shadow: none;

      span {
        display: flex;
        align-items: baseline;
        h2 {
          font-weight: bold;
          margin-right: 10px;
          margin-bottom: 0;
        }
      }

      &:focus {
        border: none;
        box-shadow: none;
      }

      &::after {
        content: none;
      }

      img {
        width: 48px;
        height: 48px;
      }
    }
    .accordion-body {
      p {
        white-space: pre-wrap;
      }
    }
  }
}
