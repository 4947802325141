.footer {
  &__item {
    text-align: center;
    flex: 1;
    padding: 36px 12px;

    a {
      display: block;
      img {
        width: 48px;
        margin-bottom: 12px;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
