.sku-slider {
  position: relative;

  &__slider {
    width: 100%;

    a {
      width: 100%;
      position: relative;
      display: block;
      padding-right: 4px;
      margin-bottom: 4px;
    }

    img {
      width: 100%;
      aspect-ratio: 1 / 1;
    }
  }
}
