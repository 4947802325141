.new-arrival-product {
  width: 100%;
  color: $gray-333;

  &__title {
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: baseline;
    color: $gray-333;

    h2 {
      font-weight: bold;
      margin-right: 10px;
    }

    span {
      margin-left: 17px;
      font-weight: normal;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__product {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px 14px;

    &__item {
      flex-basis: calc(25% - 14px);
      flex-grow: 1;
    }
  }

  &__button {
    margin-top: 50px;
    margin-bottom: 60px;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: 360px;
      min-height: 32px;
      background-color: $gray-222;
      color: $white;
      border: none;
    }
  }
}
