.carousel {
  position: relative;
  height: 56px;

  &__text {
    position: absolute;
    &[data-active='true'] {
      @include fadeIn();
      opacity: 1;
    }
    &[data-active='false'] {
      @include fadeOut();
      opacity: 0;
    }
  }

  &__action {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    img {
      cursor: pointer;
      margin-right: 2px;
    }
  }
}
