.sku-image-slider {
  .slick-track {
    margin-left: 0;
  }

  &__item {
    padding: 2px;
    border: 2px solid transparent;
    cursor: pointer;

    &[data-active='true'] {
      border-color: $gray-666;
    }

    &__ar {
      aspect-ratio: 1 / 1;
      cursor: pointer;
      background-color: $gray-d8d8d8;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 30px;
        aspect-ratio: 1 / 1;
      }
    }
  }
}
