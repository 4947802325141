.rate {
  .rc-rate-star {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $gray-222;
    margin-right: 2px;

    &:last-of-type {
      margin-right: 0;
    }
  }
  .rc-rate-star-full .rc-rate-star-second,
  .rc-rate-star-full:hover .rc-rate-star-second,
  .rc-rate-star-half .rc-rate-star-first,
  .rc-rate-star-half:hover .rc-rate-star-first {
    color: $gray-222;
  }

  .rc-rate-star:hover,
  .rc-rate-star-focused {
    transform: none;
  }
}
