.banner-b12 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  &__item {
    width: 31%;
    img {
      width: 100%;
      aspect-ratio: 440 / 100;
    }
  }
}
