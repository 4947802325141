.sku-slider {
  position: relative;

  &__action {
    $actionIconWidth: 48px;

    @mixin arrow($isPrev) {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      width: $actionIconWidth;
      height: $actionIconWidth;
      cursor: pointer;

      @if $isPrev == true {
        left: 0;
        @include media-breakpoint-up(xxl) {
          left: -($actionIconWidth / 2);
        }
      } @else {
        right: 0;
        @include media-breakpoint-up(xxl) {
          right: -($actionIconWidth / 2);
        }
      }

      img {
        img {
          width: 100%;
          aspect-ratio: 1 / 1;
        }
      }
    }

    &--prev {
      @include arrow(true);
    }

    &--next {
      @include arrow(false);
    }
  }

  &__slider {
    width: 100%;

    .slick-slide > div {
      margin: 0 6px;
    }
    .slick-list {
      margin: 0 -6px;
    }

    a {
      width: 100%;
      position: relative;

      &:last-child {
        padding-right: 0;
      }
    }

    img {
      width: 100%;
      aspect-ratio: 1 / 1;
    }
  }
}
