.header {
  .top-bar {
    color: var(--bs-gray-333);
    height: 40px;
    border-bottom: 1px solid var(--bs-gray-f0efed);
    padding: 0 5px;
    line-height: 1.1;
    text-align: center;

    li {
      flex: 1 1 auto;
      height: 100%;
      padding: 0 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:first-child {
        padding-left: 0;
        a {
          display: flex;
          align-items: center;
        }
      }
      &:last-child {
        padding-right: 0;
      }
      img {
        margin-right: 5px;
        width: 20px;
        @media screen and (max-width: 360px) {
          margin-right: 0;
        }
      }
    }

    &--active {
      background-color: var(--bs-gray-333);
      a {
        color: var(--bs-white);
      }
    }
  }

  .navbar {
    height: 48px;
    padding: 5px;
    &__left {
      flex: 1 0 0;
      &__menu {
        border: none;
        padding: 0;
        &:focus {
          box-shadow: none;
        }
      }
    }
    &__brand {
      padding: 0;
      img {
        height: 36px;
      }
    }
    &__right {
      flex: 1 0 0;

      .btn:focus {
        outline: none;
        box-shadow: none;
      }
      .nav-link {
        padding: 0;
        width: 36px;
        height: 36px;
      }
    }

    .account-cover {
      z-index: 1;
      width: 36px;
      height: 36px;

      .dropdown-menu {
        position: absolute;
      }

      li {
        padding: 0;
        min-height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        &[data-mark='true'] {
          background-color: $gray-222;
          a,
          div {
            color: $white;
          }
        }
      }
    }
  }

  .search {
    transition: all 0.3s;
    position: relative;
    &__input {
      border: 1px solid var(--bs-gray-666);
      border-radius: 0;

      height: 50px;
      padding-right: 60px;

      &:focus {
        border-color: var(--bs-gray-666);
        box-shadow: 0 0 0 1px var(--bs-white) inset, 0 0 0 2px var(--bs-gray-666) inset;
      }
    }
    &__button {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0;
      border: 1px solid var(--bs-gray-666);
      border-left: none;
      border-radius: 0;
      background-color: transparent;
    }
  }

  .search-bar {
    min-height: 58px;
    background-color: $gray-f0efed;
    padding: 5px;
    transition: all 0.3s;
    position: relative;
    &__input {
      border: 1px solid var(--bs-gray-666);
      border-radius: 0;

      max-height: 50px;
      padding-right: 60px;

      &:focus {
        border-color: var(--bs-gray-666);
        box-shadow: 0 0 0 1px var(--bs-white) inset, 0 0 0 2px var(--bs-gray-666) inset;
      }
    }
    &__button {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      border-left: none;
      border-radius: 0;
      background-color: transparent;

      img {
        max-width: 48px;
      }
    }
    .search-cover {
      position: absolute;
      z-index: 5;
      width: calc(100% - 5px * 2);
      padding: 20px 0;
      background-color: $white;
      color: $gray-222;
      border: 1px solid $gray-222;
      top: 52px;
      max-height: calc(100vh - 250px);
      overflow-y: auto;

      &[data-show='false'] {
        display: none;
      }

      &:hover {
        display: block;
      }

      h2 {
        font-weight: bold;
        margin: 0 20px 10px 20px;
        padding-bottom: 3px;
        border-bottom: 1px solid $gray-ccc;
      }

      &__suggest {
        &__clear {
          text-decoration: underline;
          display: flex;
          align-items: center;
        }

        &--search {
          li {
            padding: 0px 20px;
            min-height: 32px;
            cursor: pointer;

            a {
              width: 100%;
              min-height: inherit;
              display: flex;
              align-items: center;
            }

            &:hover {
              text-decoration: underline;
              background-color: $gray-f0efed;
            }
          }
        }

        &--product {
          margin-top: 20px;
          li {
            padding: 5px 20px;
            cursor: pointer;

            a {
              width: 100%;
              display: flex;
              align-items: center;
            }

            &:hover {
              text-decoration: underline;
              background-color: $gray-f0efed;
            }

            img {
              position: relative;
              width: 60px;
              height: 60px;
              margin-right: 10px;
            }
            h3,
            h4 {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.ham {
  width: 365px;
  border: none;

  &__header {
    flex-direction: row-reverse;
    padding: 0 6px;
    background-color: var(--bs-gray-222);

    &__button {
      padding: 0;
    }

    div {
      color: var(--bs-white);
    }
  }
}
