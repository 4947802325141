.suggest-category {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  color: $gray-333;

  &__category {
    width: 15%;

    p {
      font-weight: bold;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    li {
      text-decoration: underline;
      min-height: 28px;
      display: flex;
      align-items: center;
    }
  }
}
