.banner-b10 {
  position: relative;
  width: 100%;
  color: $gray-333;

  &__title {
    margin-bottom: 8px;
    padding: 0 20px;
    width: 100%;
    display: flex;
    color: $gray-333;
    display: flex;
    flex-direction: column-reverse;
    > *:first-child {
      font-weight: bold;
      flex: 0 0 auto;
    }
  }

  &__slider {
    margin-top: 10px;
    margin-left: 20px;
  }
}
