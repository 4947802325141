.banner-b12 {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__item {
    width: 100%;
    margin-bottom: 10px;
    img {
      width: 100%;
      aspect-ratio: 428 / 100;
    }
  }
}
