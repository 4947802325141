.category-slider {
  width: calc(100% + 140px);
  margin-left: -70px;
  padding: 0 70px;
  overflow: hidden;

  &__title {
    font-weight: bold;
    margin-bottom: 20px;
    color: $gray-222;
  }

  &__container {
    width: 100%;
    position: relative;

    &__action {
      $actionIconWidth: 48px;
      @include media-breakpoint-up(xxl) {
        &--prev {
          left: -24px !important;
        }
        &--next {
          right: -24px !important;
        }
      }

      @mixin arrow($isPrev) {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        width: $actionIconWidth;
        height: $actionIconWidth;
        cursor: pointer;

        @if $isPrev == true {
          left: 0;
        } @else {
          right: 0;
        }

        img {
          img {
            width: 100%;
            aspect-ratio: 1 / 1;
          }
        }
      }

      &--prev {
        @include arrow(true);
      }

      &--next {
        @include arrow(false);
      }
    }
  }

  &__slider {
    $cardMargin: 8px;
    .slick-list {
      overflow: visible;

      .slick-track {
        margin: 0;
      }
    }

    &__card {
      padding-right: $cardMargin;
      cursor: pointer;

      &:hover {
        p {
          text-decoration: underline;
        }
      }
      &--border {
        display: flex !important;
        padding: 4px;
        background-color: $gray-f4f4f4;

        img {
          width: 38%;
          height: 100%;
        }

        p {
          flex-grow: 1;
          padding-left: 10px;
          margin-bottom: 0;
          display: flex;
          align-items: center;
          margin-bottom: 0;
          white-space: pre-wrap;
          color: $gray-333;
          font-weight: bold;
        }
      }
    }
  }
}
