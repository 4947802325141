.header {
  .top-bar {
    $itemPadding: 10px;

    color: $gray-333;
    height: 40px;
    border-bottom: 1px solid $gray-ccc;

    &__icon {
      &--large {
        width: 36px;
      }
      &--small {
        width: 20px;
      }
    }

    ul {
      li {
        height: 100%;
        padding: 0 $itemPadding;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          .top-bar__item,
          & img {
            box-shadow: inset 0 -2px $gray-333;
          }
        }

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        img {
          height: 100%;
          padding-right: 5px;
        }

        a {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }

    &--active {
      background-color: $gray-333;
      a {
        color: $white;
      }
    }

    .account-cover {
      $seamSpacing: 1px;
      position: absolute;
      top: calc(40px - #{$seamSpacing});
      z-index: 3;
      width: calc(100% - #{$itemPadding});
      padding: calc(10px + #{$seamSpacing}) 0 10px 0;
      background-color: $white;
      color: $gray-222;
      border: 1px solid $gray-ccc;
      border-top: none;

      li {
        padding: 0;
        min-height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        &[data-mark='true'] {
          background-color: $gray-222;
          a,
          div {
            color: $white;
          }
        }
      }
    }
  }

  .main {
    min-height: 73px;

    &__logo {
      img {
        width: 150px;
      }
    }
    &__search {
      position: relative;
      max-width: 384px;
      width: 100%;
      input {
        width: 100%;
        padding: 10px 48px 10px 16px;
        min-height: 48px;
        border: 1px solid $gray-666;
        position: relative;
        &:focus,
        &:active {
          outline: none;
          border: 1px solid $gray-666;
          box-shadow: inset 0 0 0 1px $white, inset 0 0 0 2px $gray-666;
          & ~ .main__search__underline {
            transform: scaleX(1);
          }
        }
      }

      &__underline {
        width: 100%;
        position: absolute;
        border-bottom: 2px solid $gray-666;
        transform: scaleX(0);
        transform-origin: left;
        transition: 0.3s;
        left: 0;
        bottom: -2px;
      }

      img {
        position: absolute;
        right: 0;
        width: 48px;
        height: 48px;
        cursor: pointer;
      }

      .search-cover {
        position: absolute;
        z-index: 5;
        width: 100%;
        padding: 20px 0;
        background-color: $white;
        color: $gray-222;
        border: 1px solid $gray-222;

        &[data-show='false'] {
          display: none;
        }

        &:hover {
          display: block;
        }

        h2 {
          font-weight: bold;
          margin: 0 20px 10px 20px;
          padding-bottom: 3px;
          border-bottom: 1px solid $gray-ccc;
        }

        &__suggest {
          &__clear {
            text-decoration: underline;
            display: flex;
            align-items: center;
          }

          &--search {
            li {
              padding: 0px 20px;
              min-height: 32px;
              cursor: pointer;

              a {
                width: 100%;
                min-height: inherit;
                display: flex;
                align-items: center;
              }

              &:hover {
                text-decoration: underline;
                background-color: $gray-f0efed;
              }
            }
          }

          &--product {
            margin-top: 20px;
            li {
              padding: 5px 20px;
              cursor: pointer;

              a {
                width: 100%;
                display: flex;
                align-items: center;
              }

              &:hover {
                text-decoration: underline;
                background-color: $gray-f0efed;
              }

              img {
                position: relative;
                width: 60px;
                height: 60px;
                margin-right: 10px;
              }
              h3,
              h4 {
                margin: 0;
              }
            }
          }
        }
      }
    }

    &__activity {
      position: relative;
      min-height: 64px;

      &__menu {
        ul {
          li {
            margin: 0 5px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            height: 40px;
            color: $gray-333;
            cursor: pointer;

            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }

            &[data-hover='true'],
            &:hover {
              .main__activity__menu__item {
                box-shadow: inset 0 -2px $gray-222;
              }
            }
          }
        }

        &__item {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
    }
  }

  .activity-cover {
    display: flex;
    width: 950px;
    height: 420px;
    position: absolute;
    z-index: 4;
    top: 52px;
    background-color: $white;
    border: solid 1px rgba(102, 102, 102, 0.2);
    box-shadow: 1px 4px 4px 0 rgba(150, 150, 150, 0.5);

    &__list {
      width: 56%;
      height: 100%;
      background-color: $gray-f7f7f7;
      padding: 40px 65px;

      h1,
      h2,
      ul {
        @include fadeInLeft();
      }

      h1 {
        text-decoration: underline;
      }

      h2 {
        a:hover {
          text-decoration: underline;
        }
      }

      ul {
        li {
          display: flex;
          align-items: center;
          min-height: 30px;

          a:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__pic {
      width: 44%;
      padding: 38px 30px;
    }
  }

  .menu {
    position: relative;
    height: 40px;
    background-color: $gray-222;

    &__container {
      ul {
        li {
          display: flex;
          flex-grow: 1;
          justify-content: center;
          height: 100%;
          align-items: center;
          cursor: pointer;

          &[data-active='true'],
          &:hover {
            .menu__container__item {
              box-shadow: inset 0 -2px $white;
            }
          }
        }
      }
      &__item {
        display: flex;
        align-items: center;
        height: 100%;
        color: $white;
      }
    }
  }

  .menu-cover {
    position: absolute;
    width: 100%;
    background-color: $white;
    z-index: 4;

    @mixin menuList() {
      ul {
        li {
          display: flex;
          align-items: center;
          min-height: 30px;

          a {
            &:hover {
              color: $gray-333;
              text-decoration: underline;
            }
          }
        }
      }
    }

    &__sub {
      padding: 40px 0 40px 50px;
      background-color: rgba(240, 239, 237, 0.8);
      min-height: 100%;
      color: $gray-333;
      @include menuList();

      h1,
      ul {
        @include fadeInLeft();
      }

      li[data-hover='true'] {
        background-color: $white;
      }
    }

    $leftBlockWidthPct: 31%;
    $middleMargin: 40px;

    &__third {
      position: absolute;
      width: calc(#{$leftBlockWidthPct} + #{$middleMargin});
      height: 100%;
      overflow-y: auto;
      background-color: $white;
      z-index: 2;
      top: 0;
      box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.1);
      padding: 40px 55px;

      h1 {
        color: $gray-333;
      }
      @include menuList();
    }

    &__left {
      width: $leftBlockWidthPct;
      margin-right: $middleMargin;

      a:hover {
        color: $gray-333;
      }

      img {
        width: 100%;
        height: 26vw;
        object-fit: cover;
        object-position: center;
      }
    }

    &__right {
      width: 65.5%;

      a:hover {
        color: $gray-333;
      }
      img {
        width: 100%;
        height: 26vw;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
