.banner-b18 {
  width: 100%;
  color: $gray-333;

  &__title {
    padding: 0 10px;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: baseline;
    color: $gray-333;

    h2 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }

    span {
      font-weight: normal;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__product {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
      width: 49.4%;
    }
  }
}
