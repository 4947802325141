.sku-color-slider {
  .slick-track {
    margin-left: 0;
  }

  &__title {
    line-height: 24px;
  }

  &__item {
    padding: 4px;

    &--content {
      padding: 2px;
      border: 2px solid $gray-ccc;
      cursor: pointer;

      &[data-active='true'] {
        border-color: $gray-666;
      }

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}
