.customBreadcrumb {
  min-height: 50px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  ol {
    margin-bottom: 0;
    padding-left: 1px;
    margin-left: -8px;
    li {
      position: relative;
      a {
        background-color: $gray-f0efed;
        line-height: 23px;
        display: block;
        padding: 0 8px;
        margin: 0 8px;
      }
    }
  }
  &__button {
    background-color: $gray-f0efed;
    width: 23px;
    padding: 0;
    border: 0;
    border-radius: 0;
    line-height: 1;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    position: absolute;
    left: 0;
    top: 0;
  }
}
