.new-arrival-product {
  width: 100%;
  color: $gray-333;

  &__title {
    padding: 0 10px;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: baseline;
    color: $gray-333;

    h2 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }

    span {
      font-weight: normal;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__product {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
      margin-bottom: 15px;
      width: 49.4%;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__button {
    margin: 20px auto 60px;
    width: 90%;
    display: flex;
    justify-content: center;

    button {
      padding: 0 20px;
      min-height: 32px;
      background-color: $gray-222;
      color: $white;
      border: none;
    }
  }
}
