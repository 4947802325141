.sku-also-viewed {
  padding-left: 20px;
  color: $gray-222;

  &__title {
    font-weight: bold;
    min-height: 64px;
    display: flex;
    align-items: center;
  }

  &__slider {
    &__item {
      position: relative;
      cursor: pointer;

      img {
        aspect-ratio: 1 / 1;
      }

      a {
        display: block;
        outline: none;
      }
    }
  }
}
