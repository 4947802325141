.search {
  color: $gray-333;
  &__count {
    width: 95%;
    margin: 0 auto;
    margin-top: 6px;
    min-height: 28px;
    display: flex;
    align-items: flex-end;
  }

  &__title {
    width: 95%;
    margin: 0 auto;
    font-weight: bold;
    min-height: 64px;
    display: flex;
    align-items: center;
  }
}

.search-empty {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background-color: $gray-f0efed;
  padding: 25px 0;
  color: $gray-333;

  &__title {
    width: 100%;
    min-height: 64px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__suggest {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $gray-222;

    h2 {
      font-weight: bold;
      min-height: 64px;
      display: flex;
      align-items: center;
      margin: 0;
    }

    ul {
      min-height: 100px;
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
