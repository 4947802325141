.product {
  //overwrite

  .mobileSelector {
    &__close {
      width: 48px;
      position: absolute;
      bottom: 100%;
      right: 0;
      pointer-events: none;
    }

    &__option {
      width: 100%;

      &:before {
        content: '';
        display: block;
        width: 20.205%;
        min-width: 80px;
        margin-right: 12px;
      }
    }

    .product__delivery {
      .form-check {
        padding-left: 24px;

        &-input {
          width: 16px;
          height: 16px;
          margin-left: -24px;
        }

        &-label {
          margin-top: 4px;
        }
      }
    }

    .add-on-item {
      &__product__img {
        margin-right: 12px;
      }

      &__action {
        border-top: 2px solid $gray-222;
        display: flex;
        justify-content: space-between;

        position: fixed;
        top: auto;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: #fff;
        z-index: 3;

        &--addCart {
          padding: 20px 5px 19px;
          border: none;
          background-color: $gray-222;
          color: white;
          flex: 1;
        }
      }
    }
  }

  .col {
    &--left {
      flex: 0;
    }
    &--mid {
      margin-left: 46px;
      width: 1000px;
      flex: 1 1 auto;
    }
    &--right {
      margin-left: 54px;
      width: 444px;
      flex: 1 0.7 auto;
    }
  }

  &__image {
    margin-bottom: 83px;
  }

  &__title {
    font-weight: bold;
    color: $gray-333;
  }

  &__name {
    font-weight: bold;
    color: $gray-333;
  }

  &__price {
    font-weight: bold;
    &[data-highlight='true'] {
      color: $red;
    }
  }

  &__original-price,
  &__point-price {
    color: $gray-222;
    font-weight: bold;
  }

  &__detail {
    display: flex;
    align-items: center;

    &__title {
      flex: none;
      width: 80px;
    }

    &__content {
      flex: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      margin-right: 20px;
    }

    &__button {
      flex: none;
      width: 24px;
    }
  }

  &__spec {
    &__title {
      line-height: 24px;
    }

    &__item {
      flex: 1 0 0;
      word-break: keep-all;
      white-space: nowrap;
      padding: 7px;
      text-align: center;
      background-color: $gray-f0efed;
      border: 2px solid $gray-f0efed;
      cursor: pointer;

      &[data-active='true'] {
        border-color: $gray-666;
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      &.isSoldOut {
        background-color: $gray-666;
        border: 2px solid $gray-666;
        color: $white;
      }
    }
  }

  &__activity {
    p {
      margin-bottom: 6px;
    }
  }

  &__delivery {
    &__choose-shop {
      width: 100%;
      padding: 13px 8px;
      background-color: $gray-222;
      color: #fff;
    }
  }

  &__action {
    &--fix {
      position: fixed;
      top: auto;
      right: 0;
      left: 0;
      bottom: 64px;
      background-color: #fff;
      border-top: 2px solid $dark;
      border-bottom: 1px solid rgb(0 0 0 / 10%);
      z-index: 3;
    }

    &__buy {
      width: 100%;
      padding: 13px 8px;
      background-color: $gray-222;
      border: 2px solid $gray-222;
      color: #fff;
      margin-bottom: 8px;
    }

    &__addCart {
      padding: 13px 8px;
      border: 2px solid $gray-222;
      color: $gray-222;
      background-color: #fff;
      flex: 1 1 auto;

      &.isSoldOut {
        background-color: $gray-666;
        border: 2px solid $gray-666;
        color: $white;
      }
    }

    &__like {
      padding: 3px;
      width: 48px;
      height: 48px;
      border: 2px solid $gray-222;
      cursor: pointer;
      margin-left: 8px;
    }
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: $gray-f0efed;
  }
}
