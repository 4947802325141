.banner-b19 {
  width: 100%;

  &__title {
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: baseline;
    color: $gray-333;
    padding: 0 10px 0 20px;
    h2 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-weight: bold;
    }

    &__subtitle {
      font-weight: normal;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__container {
    position: relative;

    &__slider {
      margin-top: 10px;
      padding-left: 20px;

      a {
        width: 100%;
        padding-right: 10px;
        margin-bottom: 5px;
      }

      img {
        width: 100%;
        aspect-ratio: 1 / 1;
      }

      &__more {
        width: 100%;
        aspect-ratio: 1 / 1;
        padding: 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray-f7f7f7;
        text-decoration: underline;
      }
    }
  }
}
