.category-slider {
  width: 100%;

  &__title {
    font-weight: bold;
    margin-bottom: 20px;
    padding-left: var(--bs-gutter-x, 0.75rem);
    color: $gray-222;
    @include media-breakpoint-up(xxl) {
      padding-left: calc(
        100vw / 2 - #{$scrollBarWidth} / 2 - 1680px / 2 + var(--bs-gutter-x, 0.75rem)
      );
    }
  }

  &__container {
    width: 100%;
    position: relative;
  }

  &__slider {
    padding-left: 12px;

    .slick-slide {
      padding-left: 8px;
    }

    &__card {
      display: flex !important;
      aspect-ratio: 100 / 100;
      flex-direction: column;
      cursor: pointer;

      img {
        width: 100%;
      }

      p {
        flex-grow: 1;
        padding: 6px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        white-space: pre-wrap;
        color: $gray-333;
        background-color: $gray-f4f4f4;
        font-weight: bold;
      }
    }
  }
}
