.banner-b14 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  &__banner {
    width: 100%;
    margin-bottom: 10px;
    img {
      width: 100%;
      aspect-ratio: 680 / 440;
    }
  }

  &__product {
    &--left {
      width: 46%;
    }

    &--right {
      width: 46%;
    }
  }
}
